import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useStateContext } from '../Contexts/ContextProvider';
import { Button, Grid } from '@mui/material';
import axios from 'axios';

const CK_Phone = ({ nextStep }) => {
   const {
      setPhone,
      phone,
      setFirstName,
      firstName,
      setLastName,
      lastName,
      setEmail,
      email,
   } = useStateContext();
   const [iphone, setIPhone] = useState('');

   const fetchData = async (pho) => {
      await axios
         .get(
            process.env.REACT_APP_API_SERVER +
               `/api/customer/phone?phone=${pho}&store_id=${process.env.REACT_APP_STORE_ID}`
         )
         .then((res) => res.data)
         .then((data) => {
            if (data.length > 0) {
               setFirstName(data[0].first_name);
               setLastName(data[0].last_name);
               setEmail(data[0].email);
               setPhone(data[0].phone_number);
            }else{
               setPhone(pho);
               setFirstName('');
               setLastName('');
               setEmail('');
            }
         })
         .catch((err) => console.log(err));
   };
   const handleClick= async ()=>{
      let tPhone = iphone
      if (iphone.length === 11 && iphone.slice(0,1) === '1') {
         tPhone = iphone.slice(1,11)
      }
      console.log(tPhone)
      setPhone(tPhone)
      await fetchData(tPhone);
      nextStep();
   }

   return (
      <React.Fragment>
         <Grid
            container
            spacing={3}
            alignItems='center'
            justifyContent='flex-start'
            direction='column'
         >
            <Grid item xs={12} md={6}>
               <PhoneInput
                  country={'us'}
                  value={iphone}
                  onChange={(phone) => setIPhone(phone)}
               />
            </Grid>
            <Grid
                  container
                  spacing={10}
                  direction='row'
                  justifyContent='flex-end'
                  marginTop={1}
               >
                  <Grid item xs={4}>
                     <Button variant='contained' color='primary' onClick={()=>handleClick()}>
                        Next
                     </Button>
                  </Grid>
               </Grid>
         </Grid>
      </React.Fragment>
   );
};

export default CK_Phone;
