import { DesktopDatePicker } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import {
   Button,
   CircularProgress,
   Fade,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   TextField,
} from '@mui/material';
import { useServices } from '../hooks/useServices';
import { useSchedules } from '../hooks/useSchedules';
import ScheduleItem from './ScheduleItem';
import { useStateContext } from '../Contexts/ContextProvider';
import OptionDialog from './OptionDialog';
import { useAvailableEmployees } from '../hooks/useAvailableEmployees';
import { useServicesByCategory } from '../hooks/useServicesByCategory';

const Search = ({ nextStep }) => {
   const {
      serviceDate,
      serviceTime,
      serviceId,
      setServiceId,
      setServiceDate,
      setServiceTime,
      setServiceName,
      setPrice,
      setNumOfCustomers,
      setEmployeeIds,
      setSelectedStatus
   } = useStateContext();
   const [value, setValue] = React.useState(moment.utc().tz('America/New_York'));
   const [sid, setSid] = useState(process.env.REACT_APP_DEFAULT_SERVICE_ID);
   const { isLoading, data: services, error } = useServices(process.env.REACT_APP_STORE_ID);
   const { data: services1 } = useServicesByCategory(process.env.REACT_APP_STORE_ID, 1);
   const { data: services2 } = useServicesByCategory(process.env.REACT_APP_STORE_ID, 2);
   const { data: services3 } = useServicesByCategory(process.env.REACT_APP_STORE_ID, 3);
   const {
      isLoading: isLoading2,
      data: schedules,
      error: error2,
   } = useSchedules({
      start_date: value,
      service_id: sid,
      store_id: process.env.REACT_APP_STORE_ID,
   });

   const [selectedID, setSelectedID] = useState(null);
   const [minCustomer, setMinCustomer] = useState(1);
   const [openDialog, setOpenDialog] = useState(false);

   const handleChange = (newValue) => {
      let formattedDate = moment(newValue).format('YYYY-MM-DD');
      setValue(formattedDate);
      setSelectedID(null);
   };
   const handleChangeService = (event) => {
      setSid(event.target.value);
      setSelectedID(null);
   };

   const handleSelect = (id) => {
      let service = services.find((service) => service.id === parseInt(sid));
      setServiceName(service.name);
      setMinCustomer(service.num_of_customer);
      setSelectedID(id);
   };

   const handleConfirm = (id) => {
      setServiceId(schedules[id - 1].service_id);
      setServiceDate(schedules[id - 1].schedule_date.split('T')[0]);
      setServiceTime(schedules[id - 1].schedule_time);
      let service = services.find(
         (service) => service.id === schedules[id - 1].service_id
      );
      setServiceName(service.name);
      setPrice(schedules[id - 1].price);
      setOpenDialog(true);
   };

   const handleClose = (value, employeeIds,selectedStatus) => {
      //number of customers
      if (value) {
         setNumOfCustomers(value);
         setEmployeeIds(employeeIds);
         setSelectedStatus(selectedStatus);
         setOpenDialog(false);
         nextStep();
      }
      setOpenDialog(false);
   };
   useEffect(() => {
      if (moment().hour() >= 21) {
         setValue(moment().add(1, 'days'));
      }
   }, []);

   if (isLoading || isLoading2) {
      return <CircularProgress />;
   }
   console.log(schedules,"😀");
   
   return (
      <>
         <Grid
            container
            spacing={3}
            alignItems='center'
            justify='center'
            direction='row'
         >
            <Grid item xs={6}>
               <DesktopDatePicker
                  label='Date'
                  disablePast
                  inputFormat='MM/DD/YYYY'
                  value={value}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
               />
            </Grid>
            
            <Grid item xs={6}>
               <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel htmlFor='grouped-native-select'>
                     Service
                  </InputLabel>
                  <Select
                     native
                     defaultValue={sid}
                     id='grouped-native-select'
                     label='Grouping'
                     onChange={handleChangeService}
                  >
                     <option aria-label='None' value='' />
                     <optgroup label='Massage'>
                        {services1?.map((service) => (
                           <option key={service.id} value={service.id}>
                              {service.name}
                           </option>
                        ))}
                     </optgroup>
                     <optgroup label='Facial'>
                        {services2?.map((service) => (
                           <option key={service.id} value={service.id}>
                              {service.name}
                           </option>
                        ))}
                     </optgroup>
                     <optgroup label='Reflexology'>
                        {services3?.map((service) => (
                           <option key={service.id} value={service.id}>
                              {service.name}
                           </option>
                        ))}
                     </optgroup>
                  </Select>
               </FormControl>
            </Grid>
         </Grid>
         <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{ mt: 2 }}
         >
            <Grid item>
               <Button variant='contained' color='primary' onClick={() => {setSelectedID(null)}}>
                  Search
               </Button>
            </Grid>
         </Grid>
         <Fade in={isLoading || isLoading2} unmountOnExit>
            <div style={{ textAlign: 'center' }}>
               <CircularProgress style={{ margin: '20px' }} />
            </div>
         </Fade>
         <Grid
            container
            justifyContent='flex-start'
            alignItems='center'
            spacing={1}
            sx={{ mt: 2 }}
         >
            {schedules?.map((schedule) => (
               <ScheduleItem
                  selectedID={selectedID}
                  onSelect={handleSelect}
                  onConfirm={handleConfirm}
                  price={schedule.price}
                  schedule_time={schedule.schedule_time}
                  id={schedule.id}
                  key={schedule.id}
                  schedule= {schedule}
               />
            ))}
         </Grid>

         <OptionDialog
            open={openDialog}
            employees={'2'}
            customers={'1'}
            onClose={handleClose}
            start_date={serviceDate}
            start_time={serviceTime}
            service_id={serviceId}
            min_customer={minCustomer}
         />
      </>
   );
};

export default Search;
