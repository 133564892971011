import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import { Box, Button, Paper } from '@mui/material';
import Link from '@mui/material/Link';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import CK_Phone from './CK_Phone';
import CK_Contact from './CK_Contact';
import CK_Service from './CK_Service';
import CK_Finish from './CK_Finish';
import CK_Agreement from './CK_Agreement';


function Copyright() {
  let site_name = process.env.REACT_APP_SITE;
  return (
     <>
        <Typography variant='body2' color='text.secondary' align='center'>
           {process.env.REACT_APP_COMPANY_INFO}
        </Typography>
        <Typography variant='body2' color='text.secondary' align='center'>
           {'Copyright © '}
           <Link color='inherit' href={'https://' + site_name}>
              {site_name}
           </Link>{' '}
           {new Date().getFullYear()}
           {'.'}
        </Typography>
     </>
  );
}

const steps = ['Phone', 'Contact', 'Service', 'Agreement','Confirm'];
const CheckIn = () => {
  const [activeStep, setActiveStep] = React.useState(0);
   const handleNext = () => {
      setActiveStep(activeStep + 1);
   };

   const handleBack = () => {
      setActiveStep(activeStep - 1);
   };
   const firstStep = () => {
      setActiveStep(0);
   };
   function getStepContent(step) {
    switch (step) {
       case 0:
          return <CK_Phone nextStep={handleNext} />;
       case 1:
          return <CK_Contact prevStep={handleBack} nextStep={handleNext} />;

       case 2:
          return (
             <CK_Service
                prevStep={handleBack}
                nextStep={handleNext}
                firstStep={firstStep}
             />
          );
       case 3:
         return (
            <CK_Agreement
               prevStep={handleBack}
               nextStep={handleNext}
               firstStep={firstStep}
            />
         )
       case 4:
          return (
             <CK_Finish
                prevStep={handleBack}
                nextStep={handleNext}
                firstStep={firstStep}
             />
          );
       default:
          throw new Error('Unknown step');
    }
 }

 return (
    <div>
       <AppBar
          position='absolute'
          color='default'
          elevation={0}
          sx={{
             position: 'relative',
             borderBottom: (t) => `1px solid ${t.palette.divider}`,
          }}
       >
          <Toolbar>
             <div>
                <img
                   src='logo.png'
                   alt='logo'
                   width={'180px'}
                   height={'120px'}
                />
             </div>
             {/* <Typography variant='h6' color='inherit' noWrap>
                {process.env.REACT_APP_COMPANY_NAME}
             </Typography> */}
          </Toolbar>
       </AppBar>
       <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
          <Paper
             variant='outlined'
             sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
             <Typography component='h1' variant='h4' align='center'>
                Check In
             </Typography>
             <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                   <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                   </Step>
                ))}
             </Stepper>
             <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
          </Paper>
          <Copyright />
       </Container>
    </div>
 );
}

export default CheckIn