import React,{useRef, useEffect} from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import {
   Button,
   Grid,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
   TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import SignatureCanvas from 'react-signature-canvas';

import { useServices } from '../hooks/useServices';
import { useStateContext } from '../Contexts/ContextProvider';
import { useCheckIn } from '../hooks/useCheckIn';

const CK_Service = ({ prevStep, nextStep }) => {
   const { serviceNames, setServiceNames, massagePressure, setMassagePressure,phone,firstName,lastName,email } =
      useStateContext();
   const { isLoading, data } = useServices(process.env.REACT_APP_STORE_ID);
   const { checkIn } = useCheckIn();
   const [service, setService] = React.useState([]);
   const [pressure, setPressure] = React.useState('medium');
   const [error, setError] = React.useState(null);
   const theme = useTheme();
   const sigCanvasRef = useRef(null);
   const ITEM_HEIGHT = 48;
   const ITEM_PADDING_TOP = 8;
   const MenuProps = {
      PaperProps: {
         style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
         },
      },
   };

   function getStyles(name, service, theme) {
      return {
         fontWeight:
            service.indexOf(name) === -1
               ? theme.typography.fontWeightRegular
               : theme.typography.fontWeightMedium,
      };
   }

   const handleChange = (event) => {
      const {
         target: { value },
      } = event;
      setService(
         // On autofill we get a stringified value.
         typeof value === 'string' ? value.split(',') : value
      );
   };
   const handlePressureChange = (event) => {
      setPressure(event.target.value);
   };

   const saveCanvas = () => {
      const sigCanvas = sigCanvasRef.current;
      const canvas = sigCanvas.getCanvas();
      let canvasUrl = canvas.toDataURL('image/png');
      localStorage.setItem('canvas', canvasUrl);
    }
   const handleSubmit = (e) => {
      e.preventDefault();
      // setFormErrors(validate(formValues));
      // setIsSubmit(true);

      setServiceNames(service);
      setMassagePressure(pressure);
      saveCanvas();

      //upload data server
      const values = {
         store_id: process.env.REACT_APP_STORE_ID,
         phone_number: phone,
         service_names: service,
         pressure: massagePressure,
         canvas: localStorage.getItem('canvas'),
      };

      checkIn(values, {
         onSuccess: () => {
            console.log('success');
            nextStep();
         },
         onError: (error) => {
            console.log('error', error);
            setError(error.response.data);
         },
      });
   };

   useEffect(() => {
      const sigCanvas = sigCanvasRef.current;
      const canvas = sigCanvas.getCanvas();
      const context = canvas.getContext('2d');
  
      const image = new Image();
      image.src = require('../human-body.png')
  
      image.onload = () => {
         context.drawImage(image, 0, 0, 200, 200);
      };
   },[]);
   return (
      <React.Fragment>
         <form onSubmit={handleSubmit} noValidate>
            <Grid
               container
               spacing={3}
               alignItems='center'
               justifyContent='flex-start'
               direction='column'
            >
               <Grid item xs={12}>
                  <Grid item xs={12} md={6}>
                     <Typography variant='h8'>
                        Please select your service
                     </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                     <div>
                        <FormControl sx={{ m: 1, width: 200 }}>
                           <InputLabel id='demo-multiple-chip-label'>
                              Services
                           </InputLabel>
                           <Select
                              labelId='demo-multiple-chip-label'
                              id='demo-multiple-chip'
                              // multiple
                              value={service}
                              onChange={handleChange}
                              input={
                                 <OutlinedInput
                                    id='select-multiple-chip'
                                    label='Chip'
                                 />
                              }
                              renderValue={(selected) => (
                                 <Box
                                    sx={{
                                       display: 'flex',
                                       flexWrap: 'wrap',
                                       gap: 0.5,
                                    }}
                                 >
                                    {selected.map((value) => (
                                       <Chip key={value} label={value} />
                                    ))}
                                 </Box>
                              )}
                              MenuProps={MenuProps}
                           >
                              {data?.map((svs) => (
                                 <MenuItem
                                    key={svs.id}
                                    value={svs.name}
                                    style={getStyles(svs.name, service, theme)}
                                 >
                                    {svs.name}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                     </div>
                  </Grid>
                  <div>
                     <FormControl>
                        <FormLabel id='demo-radio-buttons-group-label'>
                           Pressure
                        </FormLabel>
                        <RadioGroup
                           aria-labelledby='demo-radio-buttons-group-label'
                           value={pressure}
                           onChange={handlePressureChange}
                           name='radio-buttons-group'
                        >
                           <FormControlLabel
                              value='Light'
                              control={<Radio />}
                              label='Light'
                           />
                           <FormControlLabel
                              value='Medium'
                              control={<Radio />}
                              label='Medium'
                           />
                           <FormControlLabel
                              value='Deep'
                              control={<Radio />}
                              label='Deep'
                           />
                        </RadioGroup>
                     </FormControl>
                  </div>
                  <div>
                  <SignatureCanvas
                     ref={sigCanvasRef}
                     penColor='red'
                     canvasProps={{ width: 200, height: 200 }}
                     />
                  </div>
                  <div>
                     {error !== '' && error !== null ? (
                        <Typography variant='body1' color='error'>
                           {error}
                        </Typography>
                     ) : null}
                  </div>

                  <div>
                     <Button onClick={prevStep}>Back</Button>
                     <Button type='submit' variant='contained' color='primary'>
                        Next
                     </Button>
                  </div>
               </Grid>
            </Grid>
         </form>
      </React.Fragment>
   );
};

export default CK_Service;
