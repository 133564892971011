import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

function CK_Agreement({ prevStep, nextStep }) {
   const sigCanvasRef = useRef(null);
   const [sign, setSign] = useState();
   const [url, setUrl] = useState();

   const saveCanvas = () => {
      const sigCanvas = sigCanvasRef.current;
      const canvas = sigCanvas.getCanvas();
      let canvasUrl = canvas.toDataURL('image/png');
      localStorage.setItem('canvas', canvasUrl);
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      nextStep();
   };

   useEffect(() => {
      const sigCanvas = sigCanvasRef.current;
      const canvas = sigCanvas.getCanvas();
      const context = canvas.getContext('2d');

      const image = new Image();
      image.src = require('../signature.png');

      image.onload = () => {
         context.drawImage(image, 0, 0, 300, 100);
      };
   }, []);
   return (
      <React.Fragment>
         <form onSubmit={handleSubmit} noValidate>
            <Grid
               container
               spacing={3}
               alignItems='center'
               justifyContent='flex-start'
               direction='column'
            >
               <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={6}>
                     <Typography variant='h6'>Agreement</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                     <p></p>
                     <p></p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                     <Typography variant='h8'>
                        When you experience any discomfort during the massage,
                        please immediately inform the massage therapist to cease
                        their work and report to the front desk to notify the
                        on-duty manager, or contact the authorities directly.
                        Failing to do so implies that you have no objections to
                        our service.
                     </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} marginTop={5}>
                     <div>
                        <SignatureCanvas
                           ref={sigCanvasRef}
                           penColor='red'
                           canvasProps={{ width: 300, height: 100 }}
                        />
                     </div>
                  </Grid>
               </Grid>
               <Grid
                  container
                  spacing={10}
                  direction='row'
                  justifyContent='flex-end'
               >
                  <Grid item xs={8}>
                     <Button onClick={prevStep}>Back</Button>
                     <Button type='submit' variant='contained' color='primary'>
                        Next
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </React.Fragment>
   );
}

export default CK_Agreement;
